<template>
  <div>
    <!-- <page-title v-if="!hideTitle"></page-title> -->

    <function-entry></function-entry>

    <!-- <b-card>
            <b-card-text class="text-center">
                <p>一键诊断文字介绍</p>

                <b-button variant="primary">
                    <b-icon-hand-index-fill
                        class="mr-50"
                    ></b-icon-hand-index-fill>
                    一键诊断</b-button
                >  

                <b-row class="mt-2">
                    <b-col cols="4" offset="2">
                        <b-avatar variant="light-primary" size="66">
                            <b-icon-search scale="2.5"></b-icon-search
                        ></b-avatar>
                        <b-progress
                            value="60"
                            max="100"
                            show-progress
                            animated
                            class="mt-2"
                        ></b-progress>
                        <p class="mt-2">奋力诊断中...</p>
                        <b-button variant="primary">
                            <b-icon-check-circle-fill
                                class="mr-50"
                            ></b-icon-check-circle-fill>
                            查看诊断结果</b-button
                        >
                    </b-col>
                    <b-col cols="4"
                        ><b-card
                            border-variant="primary"
                            style="height: 15rem"
                            class="mb-0"
                        >
                            代码可视化
                        </b-card></b-col
                    >
                </b-row>
            </b-card-text>
        </b-card> -->

    <b-row>
      <b-col cols="2">
        <total-health :topInfos="topInfos"></total-health>
      </b-col>
      <b-col cols="3">
        <physical-monitoring :topInfos="topInfos"></physical-monitoring>
      </b-col>
      <b-col cols="4">
        <Diagnosis :topInfos="topInfos"></Diagnosis>
      </b-col>
      <b-col cols="3">
        <system-info :topInfos="topInfos"></system-info>
      </b-col>
    </b-row>

    <line-chart :agent_line="agentLine"></line-chart>
    <pie-chart :agent_pie="agentPie"></pie-chart>

    <b-row>
      <b-col cols="6">
        <data-visual :height="294"></data-visual>
      </b-col>

      <b-col cols="3">
        <fault-event></fault-event>
      </b-col>

      <b-col cols="3">
        <diagnostic-event></diagnostic-event>
      </b-col>
    </b-row>

    <!-- <b-row>
            <b-col cols="12" class="text-center">
                <p>
                    <b-button variant="primary" @click="ToAgentList">
                        <b-icon-list-ul class="mr-50"></b-icon-list-ul>
                        查看故障列表</b-button
                    >
                    <b-button disabled class="ml-1">
                        <b-icon-download class="mr-50"></b-icon-download>
                        下载诊断报告</b-button
                    >
                </p>
            </b-col>
        </b-row> -->

    <b-row>
      <b-col cols="12" class="text-center">
        <p>&copy; Powered by CABR iBee Engine. All rights reserverd.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import JsonExcel from "vue-json-excel";
import VJstree from "vue-jstree";
import PhysicalMonitoring from "./components/PhysicalMonitoring.vue";
import TotalHealth from "./components/TotalHealth.vue";
import SystemInfo from "./components/SystemInfo.vue";
import FunctionEntry from "./components/FunctionEntry.vue";
import DataVisual from "./components/DataVisual.vue";
import Diagnosis from "./components/Diagnosis.vue";
import FaultDiagnosis from "./components/FaultDiagnosis.vue";
import PieChart from "./components/PieChart.vue";
import LineChart from "./components/LineChart.vue";
import FaultEvent from "./components/FaultEvent.vue";
import DiagnosticEvent from "./components/DiagnosticEvent.vue";
import router from "@/router";
import store from "@/store";
import { routerParams } from "@/libs/utils/routerParams";
import { computed, onMounted, reactive, toRefs, ref } from "@vue/composition-api";
import { showToast } from "@/libs/utils/showToast";

export default {
  name: "index",
  directives: {
    Ripple,
  },
  components: {
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
    VJstree,
    TotalHealth,
    PhysicalMonitoring,
    SystemInfo,
    FunctionEntry,
    DataVisual,
    Diagnosis,
    FaultDiagnosis,
    PieChart,
    LineChart,
    FaultEvent,
    DiagnosticEvent,
  },
  props: ["hide-title"],
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      agentLine: {},
      agentPie: [],
      topInfos: {}
    });

    const ToAgentList = () => {
      router.push({ path: "/AgentList" });
    };

    // 柱状图 逐月逐日。。
    const getAgentLine = () => {
      console.log("获取数据");
      const project_id = event.project_id;
      store
        .dispatch("projects/fetchAgentLine", project_id)
        .then((response) => {
          console.log("获取逐月逐日", response.data);
          event.agentLine = response.data;
        })
        .catch((error) => {
          console.log("请求失败");
          showToast(error);
        });
    };

    // 饼图
    const getAgentPie = () => {
      const project_id = event.project_id;
      store
        .dispatch("projects/fetchAgentPie", project_id)
        .then((response) => {
          console.log("饼图数据", response.data);
          event.agentPie = response.data;
        })
        .catch((error) => {
          console.log("请求失败");
          showToast(error);
        });
    };

    const getNotice = () => {
      const project_id = event.project_id;
      store
          .dispatch("diagnosis/fetchNotice", project_id)
    }

    // 首页顶部信息
    const getIndexTopInfos = ()=>{
      const project_id = event.project_id;
      store
        .dispatch("projects/getIndexTopInfos", project_id)
        .then((response) => {
          console.log("顶部信息数据", response.data);
          event.topInfos = response.data;
        })
        .catch((error) => {
          console.log("请求失败");
          showToast(error);
        });
    }

    onMounted(() => {
      getAgentLine();
      getAgentPie();
      getNotice();
      getIndexTopInfos();
    });

    return {
      ...toRefs(event),
      // data
      ToAgentList,
    };
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <b-card>
    <b-row>
      <b-col cols="8">
        <b-card-title class="font-weight-bolder">
          <b-icon-exclamation-triangle
              variant="danger"
              class="mr-50"
          ></b-icon-exclamation-triangle>
          故障事件
        </b-card-title>
      </b-col>
      <b-col cols="4" class="font-weight-bolder text-right">
        {{ currentTime }}
      </b-col>
    </b-row>


    <b-card-text
        id="project-detail-operation-scroll-notice"
        style="height: 300px; overflow-y: scroll; overflow-x: hidden"
        @mouseenter="scroll_stop('#project-detail-operation-scroll-notice')"
        @mouseleave="scroll_active('#project-detail-operation-scroll-notice')"
    >
      <app-timeline>
        <!-- <app-timeline-item
          v-for="(item, index) in store.state.diagnosis.notices"
          :key="'agent_' + index"
          :title="item.fault_name"
          :subtitle="item.eqname"
          :variant="
            item.degree === '重要'
              ? 'danger'
              : item.degree === '一般'
              ? 'info'
              : 'warning'
          "
        >
        </app-timeline-item> -->

        <app-timeline-item
            :variant="
            item.degree === '重要'
              ? 'danger'
              : item.degree === '一般'
              ? 'info'
              : 'warning'
          "
            v-for="(item, index) in store.state.diagnosis.notices"
            :key="'agent_' + index"
        >
          <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          >
            <h6>{{ item.fault_name }}</h6>
            <!-- <small class="text-muted">12 min ago</small> -->
          </div>
          <p class="text-muted">{{ item.eqname }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-card-text>
  </b-card>
</template>
<script>
import {onMounted, ref, reactive, toRefs} from "@vue/composition-api";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {scroll_active, scroll_stop} from "@/libs/utils/autoScroll";
import store from "@/store";

export default {
  name: "FaultEvent",
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  setup() {
    const event = reactive({
      projectOperation: [
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "一般",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "警告",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "警告",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "一般",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "警告",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "一般",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "一般",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "警告",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "一般",
        },
        {
          time: "2023-01-01",
          Description: "Default Description 1",
          Degree: "重要",
        },
        {
          time: "2023-02-01",
          Description: "Default Description 2",
          Degree: "警告",
        },
      ],
      currentTime: null
    });

    const getCurrentDate = () => {
      // 创建一个 Date 对象来获取当前系统时间
      const currentDate = new Date();

      // 获取年、月、日
      const year = currentDate.getFullYear();
      // getMonth() 返回的是 0-11，所以需要加 1
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      // 格式化输出结果
      const formattedDate = `${year}-${month}-${day}`;

      event.currentTime = formattedDate

      // 打印输出结果
      console.log(formattedDate); // 输出类似 "2024-01-31" 的结果
    };

    onMounted(() => {
      scroll_active("#project-detail-operation-scroll-notice");
      getCurrentDate()
    });

    return {
      ...toRefs(event),
      scroll_active,
      scroll_stop,
      store,
    };
  },
};
</script>
<style lang="scss" scoped>
.timeline-item {
  padding-left: 1.5rem !important;

  div > h6 {
    font-size: 0.9rem !important;
  }
}
</style>

<template>
  <b-row>
    <!-- <b-col cols="2" v-for="(item,index) in agentPie" :key="index">
      <b-card>
        <b-card-title class="font-weight-bolder">故障分类{{ index }}</b-card-title>
        <div :id="'fault-category-pie'+index" class="chart-pie"></div>
      </b-card>
    </b-col> -->

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-water variant="info" class="mr-50"></b-icon-water>
          程度分类
        </b-card-title>
        <div id="fault-category-pie1" class="chart-pie"></div>
      </b-card>
    </b-col>

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-hdd variant="primary" class="mr-50"></b-icon-hdd>
          设备分类</b-card-title
        >
        <div id="fault-category-pie2" class="chart-pie"></div>
      </b-card>
    </b-col>

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-cash variant="warning" class="mr-50"></b-icon-cash>
          方程分类</b-card-title
        >
        <div id="fault-category-pie3" class="chart-pie"></div>
      </b-card>
    </b-col>

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-exclamation-lg
            variant="danger"
            class="mr-50"
          ></b-icon-exclamation-lg>
          主要分类</b-card-title
        >
        <div id="fault-category-pie4" class="chart-pie"></div>
      </b-card>
    </b-col>

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-bounding-box
            variant="info"
            class="mr-50"
          ></b-icon-bounding-box>
          系统分类</b-card-title
        >
        <div id="fault-category-pie5" class="chart-pie"></div>
      </b-card>
    </b-col>

    <b-col cols="2">
      <b-card>
        <b-card-title class="font-weight-bolder">
          <b-icon-ui-radios variant="success" class="mr-50"></b-icon-ui-radios>
          类型分类</b-card-title
        >
        <div id="fault-category-pie6" class="chart-pie"></div>
      </b-card>
    </b-col>
  </b-row>

  <!-- <b-card>
        <b-card-title class="d-flex align-items-center font-title">
            故障诊断
        </b-card-title>
        <b-row>
            <b-col cols="6">
                <div class="mb-1">
                    <span class="font-title">故障分类</span>
                    <div id="fault-category-pie1" style="height: 11.5rem"></div>
                </div>

                <div>
                    <span class="font-title">子系统设计</span>
                    <div id="fault-category-pie2" style="height: 11.5rem"></div>
                </div>

                <div>
                    <span class="font-title">位置分裂统计</span>
                    <div id="fault-category-pie3" style="height: 11.5rem"></div>
                </div>
            </b-col>
            <b-col cols="6">
                <div class="mb-1">
                    <span class="font-title">设备统计</span>
                    <div id="fault-category-pie4" style="height: 11.5rem"></div>
                </div>

                <div>
                    <span class="font-title">程度统计</span>
                    <div id="fault-category-pie5" style="height: 11.5rem"></div>
                </div>

                <div>
                    <span class="font-title">类型分类</span>
                    <div id="fault-category-pie6" style="height: 11.5rem"></div>
                </div>
            </b-col>
        </b-row>
    </b-card> -->
</template>
<script>
import {
  onMounted,
  reactive,
  ref,
  computed,
  watch,
  toRefs,
} from "@vue/composition-api";
import { $themeColors } from "@themeConfig";
import { Pie, Column } from "@antv/g2plot";
import { routerParams } from "@/libs/utils/routerParams";

export default {
  name: "PieChart",
  props: {
    agent_pie: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data1 = [
      { type: "分类一", value: 27 },
      { type: "分类二", value: 25 },
      { type: "分类三", value: 20 },
      { type: "分类四", value: 18 },
      { type: "分类五", value: 10 },
    ];

    const pieData = [
      { type: "暖通", value: 13 },
      { type: "能源", value: 24 },
      { type: "电气", value: 46 },
      { type: "给排水", value: 56 },
    ];

    const pieData1 = [
      { type: "重要", value: 64 },
      { type: "一般", value: 32 },
      { type: "提醒", value: 6 },
    ];

    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      agentPie: computed(() => {
        console.log("饼图====", props.agent_pie);
        return props.agent_pie;
      }),
    });

    const pieInstancePools = {};

    watch(
      () => event.agentPie,
      (agentPie) => {
        console.log("agentPie数据发生变化===");
        if (agentPie) {
          pieChartInit(agentPie);
        }
      }
    );

    const pieChartInit = async (agentPie) => {
      // degree  eqname  function_name  primary_class  system  type
      if (!agentPie?.length) return;
      // 初始化第一个图表
      await initPieChart(
        "fault-category-pie1",
        agentPie[0].map((item) => ({ type: item.degree, value: item.num }))
      );

      // 可以在此添加其他异步操作，确保正确的初始化顺序

      // 初始化第二个图表
      // const data2 = [...]; // 准备第二个图表的数据
      await initPieChart(
        "fault-category-pie2",
        agentPie[1].map((item) => ({ type: item.eqname, value: item.num }))
      );
      await initPieChart(
        "fault-category-pie3",
        agentPie[2].map((item) => ({
          type: item.function_name,
          value: item.num,
        }))
      );
      await initPieChart(
        "fault-category-pie4",
        agentPie[3].map((item) => ({
          type: item.primary_class,
          value: item.num,
        }))
      );
      await initPieChart(
        "fault-category-pie5",
        agentPie[4].map((item) => ({ type: item.system, value: item.num }))
      );
      await initPieChart(
        "fault-category-pie6",
        agentPie[5].map((item) => ({ type: item.type, value: item.num }))
      );
    };

    // const pieChartInit = () =>{
    //     event.agentPie.forEach((item,index) => {
    //         console.log("饼图数据===",index,item)
    //         initPieChart('fault-category-pie'+index, item)
    //     })
    // }

    const initPieChart = async (containerId, data) => {
      if (containerId in pieInstancePools) {
        pieInstancePools[containerId].updated({ data });
        return;
      }
      const piePlot = new Pie(containerId, {
        theme: {
          colors10: Object.values($themeColors),
        },
        appendPadding: 10,
        data: data,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.6,
        legend: {
          position: "left",
        },
        label: {
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
        },
        tooltip: {
          fields: ["type", "value"],
        },
        // interactions: [
        //   { type: "element-selected" },
        //   { type: "element-active" },
        //   { type: "default",
        //   cfg:{

        //   }
        // }
        // ],
        interactions: [{ type: "element-active" }],
        state: {
          // 设置 active 激活状态的样式
          active: {
            animate: { duration: 100, easing: "easeLinear" },
            style: {
              lineWidth: 1,
              stroke: "#000",
            },
          },
        },
        statistic: {
          title: {
            formatter: () => "合计",
            offsetY: -8,
            style: {
              fontSize: "0.9rem",
              color: "",
            },
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "1.286rem",
              fontFamily: "LcdD",
              fontWeight: "normal",
              color: "",
            },
          },
	        style:{

	        }
        },
        pieStyle:{
          lineWidth:0,
        }
        // interactions: [
        //   {
        //     type: "element-selected",
        //   },
        //   {
        //     type: "element-highlight",
        //   },
        // {
        //   type: "pie-statistic-active",
        //   cfg: {
        //     start: [
        //       {
        //         trigger: "element:mouseenter",
        //         action: "pie-statistic:change",
        //       },
        //       {
        //         trigger: "legend-item:mouseenter",
        //         action: "pie-statistic:change",
        //       },
        //     ],
        //     end: [
        //       {
        //         trigger: "element:mouseleave",
        //         action: "pie-statistic:reset",
        //       },
        //       {
        //         trigger: "legend-item:mouseleave",
        //         action: "pie-statistic:reset",
        //       },
        //     ],
        //   },
        // },
        // ],
      });

      piePlot.render();
      pieInstancePools[containerId] = piePlot;
    };

    onMounted(() => {
      pieChartInit();
    });
    return {
      ...toRefs(event),
    };
  },
};
</script>
<style lang="scss" scoped>
.chart-pie {
  height: 15rem;
}
</style>

<template>
  <b-card>
    <b-card-title class="font-weight-bolder">
      <feather-icon icon="display" size="25" />
      <b-icon-display
                    variant="warning"
                    class="mr-50"
                ></b-icon-display>
       实体监控 
    </b-card-title>
    <b-row>
      <b-col cols="4" class="text-center">
        <b-avatar size="48" variant="light-warning">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">服务器状态</h5>
        <h3 class="my-0" style="font-size: 17px">
          <span class="text-warning">0</span>
          <span class="text-muted">/3</span>
        </h3>
      </b-col>

      <b-col cols="4" class="text-center">
        <b-avatar size="48" variant="light-info">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">微服务状态</h5>
        <h3 class="my-0" style="font-size: 17px">
          <span class="text-info">0</span>
          <span class="text-muted">/3</span>
        </h3>
      </b-col>

      <b-col cols="4" class="text-center">
        <b-avatar size="48" variant="light-success">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">数据库状态</h5>
        <h3 class="my-0" style="font-size: 17px">
          <span class="text-success">0</span>
          <span class="text-muted">/3</span>
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  watch,
  toRefs,
} from "@vue/composition-api";
export default {
  name: "PhysicalMonitoring",
  props: {
    topInfos: [Object],
  },
  setup(props) {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <b-card>
    <b-card-title class="font-weight-bolder"> 
      <b-icon-box variant="info" class="mr-50"></b-icon-box>
      系统信息
    </b-card-title>
    <b-row v-if="topInfo">
      <b-col cols="3" class="text-center">
        <b-avatar size="48" variant="light-primary">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">数据总量</h5>
        <h3 class="my-0">
          <span class="text-primary" style="font-size: 17px">{{ numberFormat(topInfo.data_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.data_total)[1] }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar size="48" variant="light-success">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">总点位数</h5>
        <h3 class="my-0">
          <span class="text-success" style="font-size: 17px">{{ numberFormat(topInfo.point_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.point_total)[1] }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar size="48" variant="light-danger">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">即时规则</h5>
        <h3 class="my-0">
          <span class="text-danger" style="font-size: 17px">{{ numberFormat(topInfo.immed_rule_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.immed_rule_total)[1] }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar size="48" variant="light-info">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">延时规则</h5>
        <h3 class="my-0">
          <span class="text-info" style="font-size: 17px">{{ numberFormat(topInfo.delay_rule_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.delay_rule_total)[1] }}</span></span>
        </h3>
      </b-col>

      <!-- <b-col cols="2" class="text-center">
                <b-avatar size="48" variant="light-warning">
                    <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
                </b-avatar>
                <h5 class="font-weight-bolder mt-1">总规则数</h5>
                <h3 class="my-0">
                    <span class="text-warning">0</span>
                </h3>
            </b-col>

            <b-col cols="2" class="text-center">
                <b-avatar size="48" variant="light-secondary">
                    <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
                </b-avatar>
                <h5 class="font-weight-bolder mt-1">总规则数</h5>
                <h3 class="my-0">
                    <span class="text-secondary">0</span>
                </h3>
            </b-col> -->
    </b-row>
  </b-card>
</template>
<script>
import {
  computed,
  onMounted,
  reactive,
  watch,
  toRefs,
} from "@vue/composition-api";

export default {
  name: "SystemInfo",
  props: {
    topInfos: [Object],
  },
  setup(props) {
    const event = reactive({
      topInfo: computed(() => {
        console.log(props.topInfos);
        return props.topInfos;
      }),
    });

    const numberFormat = (value) => {
      if (!value) return 0;
      let unit = '';
      let k = 10000,
          sizes = ['', '万', '亿', '万亿'],
          i;
      if (value < k) {
        value = value;
      } else {
        i = Math.floor(Math.log(value) / Math.log(k));
        value = ((value / Math.pow(k, i))).toFixed(2);
        unit = sizes[i];
      }
      return [value, unit];
    }


    return {
      ...toRefs(event),
      numberFormat
    };
  }
  ,
}
;
</script>
<style lang="scss" scoped></style>

<template>
    <div>
        <b-row>
            <b-col cols="4" v-for="(item, index) in chartNames" :key="index">
                <b-card>
                    <b-card-title class="font-weight-bolder">
                        <!-- <b-icon
                            :icon="item.icon"
                            :variant="item.variant"
                            class="mr-50"
                        ></b-icon> -->
                        {{ item.title }}
                    </b-card-title>
                    <div
                        :id="'fault-distribution-column' + item.key"
                        class="chart-col"
                    ></div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {
    onMounted,
    reactive,
    ref,
    computed,
    watch,
    toRefs,
} from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import { $themeColors } from "@themeConfig";
import { Column, Line } from "@antv/g2plot";

export default {
    name: "LineChart",
    props: {
        agent_line: [Object],
    },
    setup(props) {
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            agentLine: computed(() => {
                console.log(props.agent_line);
                return props.agent_line;
            }),
            chartNames: computed(() => {
                let keys = [
                    {
                        key: "month",
                        title: "逐月故障分布",
                        icon: "calendar",
                        variant: "primary",
                    },
                    {
                        key: "day",
                        title: "逐日故障分布",
                        icon: "calendar-day",
                        variant: "success",
                    },
                    {
                        key: "hour",
                        title: "逐时故障分布",
                        icon: "clock",
                        variant: "info",
                    },
                ];
                return keys;
            }),
            newData: {},
        });

        watch(
            () => event.agentLine,
            (agentLine) => {
                console.log("数据发生变化===");
                if (agentLine) {
                    console.log("数据发生变化");
                    //   columnChartInit();
                    event.chartNames.map((name) => {
                        const key = name.key;
                        console.log("key", key);
                        if (agentLine[key]) {
                            event.newData = event.chartNames.map(
                                ({ key, title }) => {
                                    return {
                                        key,
                                        title,
                                        data: event.agentLine[key],
                                    };
                                    F;
                                }
                            );
                            console.log("最新组合数据", event.newData);
                        }
                    });
                    chartInit();
                }
            }
        );

        // const columnChartInit = async () => {
        //   // console.log("柱状图数据",event.agentLine.month)
        //   await initColumnChart("fault-distribution-column", event.agentLine.month);
        //   await initColumnChart("fault-distribution-column2", event.agentLine.day);
        //   await initColumnChart("fault-distribution-column3", event.agentLine.hour);

        //   event.agentLine;
        // };

        const chartInit = () => {
            event.newData.map((item, index) => {
                // console.log("item.key===", item.key);
                initColumnChart(
                    "fault-distribution-column" + item.key,
                    item.data,
                    index
                );
            });
        };

        const initColumnChart = async (containerId, data, index) => {
            console.log(index, "indexxxxx");
            if (index >= 1) {
                const linePlot = new Line(containerId, {
                    data: data,
                    xField: "date",
                    yField: "num",
                    label: {
                        // 可手动配置 label 数据标签位置
                        position: "middle", // 'top', 'bottom', 'middle',
                        // 配置样式
                        style: {
                            fill: "#FFFFFF",
                            opacity: 0.6,
                        },
                    },
                    smooth: true,
                    xAxis: {
                        label: {
                            autoHide: true,
                            autoRotate: false,
                            formatter: (v) => {
                                if (/^[0-9]{4}\-[0-9]{2}$/.test(v)) {
                                    return v.split("-")[1];
                                } else if (
                                    /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(v)
                                ) {
                                    return v.split("-")[2];
                                } else {
                                    return "";
                                }
                            },
                        },
                    },
                    meta: {
                        type: {
                            alias: "类别",
                        },
                        sales: {
                            alias: "销售额",
                        },
                    },
                });
                linePlot.render();
                return;
            }
            const columnPlot = new Column(containerId, {
                data: data,
                xField: "date",
                yField: "num",
                label: {
                    position: "middle", // 'top', 'bottom', 'middle',
                    // 配置样式
                    /* style: {
                        fill: "#1890ff",
                        opacity: 1,
                    }, */
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                        formatter: (v) => {
                            if (/^[0-9]{4}\-[0-9]{2}$/.test(v)) {
                                return v.split("-")[1];
                            } else if (
                                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(v)
                            ) {
                                return v.split("-")[2];
                            } else {
                                return v.split(" ")[1];
                            }
                        },
                    },
                    grid: null,
                    line: null,
                    tickLine: null,
                },
                yAxis: {
                    grid: null,
                    line: null,
                    label: null,
                },
                style: { radius: [20, 20, 0, 0] },
            });

            columnPlot.render();
        };

        onMounted(() => {
            console.log("props", props.agent_line);
            //   columnChartInit();
            // chartInit
        });

        return {
            ...toRefs(event),
        };
    },
};
</script>
<style lang="scss" scoped>
.chart-col {
    height: 15rem;
}
</style>
